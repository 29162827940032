<!-- =========================================================================================
    File Name: CourseList.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-card bg-dark p-8">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow">
          <vs-dropdown class="cursor-pointer mr-2" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2">
                {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
                {{ courseData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : courseData.length }} of
                {{ totalCourses }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-button @click="addNewData" class="ml-2 mr-2 round" color="primary" type="gradient">Create New Course</vs-button>
          <vs-button
            @click="
              showArchived = !showArchived;
              loadProductBuffer();
            "
            class="ml-2 round"
            color="danger"
            type="gradient"
            >{{ showArchived === true ? 'Hide Archived' : 'Show Archived' }}</vs-button
          >
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          @input="updateSearchQuery"
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          placeholder="Search..."
          v-model="searchQuery"
        />
        <!-- <vs-button @click="gridApi.exportDataAsCsv()" class="mb-4 md:mb-0">Export as CSV</vs-button> -->
      </div>
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="courseData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable"
      >
      </ag-grid-vue>
      <vs-pagination :total="totalPages" v-model="currentPage" />
    </div>
    <vs-popup :active.sync="createCoursePopup" title="Create Course">
      <div class="popup-input" v-if="createCourseData">
        <p class="mb-1 font-semibold">Name</p>
        <vs-input v-model="createCourseData.name" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Description</p>
        <vs-textarea v-model="createCourseData.description" height="100px" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Code</p>
        <vs-input v-model="createCourseData.code" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Thumbnail</p>
        <vs-input v-model="createCourseData.thumbnail" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Status</p>
        <div class="flex items-center mb-3">
          <vs-radio v-model="createCourseData.status" vs-name="courseStatus" vs-value="active" class="mr-4">Active</vs-radio>
          <vs-radio v-model="createCourseData.status" vs-name="courseStatus" vs-value="hidden" class="mr-4">Hidden</vs-radio>
          <vs-radio v-model="createCourseData.status" vs-name="courseStatus" vs-value="disabled" class="mr-4">Disabled</vs-radio>
        </div>

        <div class="flex justify-end mb-2">
          <vs-button class="font-bold" @click="createCourse">Create Course</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import TableName from './components/TableName.vue';
import TableActions from './components/TableActions.vue';
import TableID from './components/TableID';
import TableCode from './components/TableCode';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableName,
    TableActions,
    TableID,
    TableCode,
  },
  data() {
    return {
      showArchived: false,

      createCoursePopup: false,
      createCourseData: {
        name: null,
      },

      courseData: [],
      totalCourses: 0,

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 400,
          cellRendererFramework: 'TableName',
        },
        {
          headerName: 'Code',
          field: 'code',
          filter: true,
          cellRendererFramework: 'TableCode',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
        },
        {
          headerName: 'Actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableName,
        TableActions,
        TableID,
        TableCode,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    addNewData() {
      this.createCoursePopup = !this.createCoursePopup;
    },
    createCourse() {
      if (this.createCourseData.name === null) {
        return this.$vs.notify({
          title: 'Failed to create course',
          text: 'Please provide a Course Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .post('course', this.createCourseData)
        .then((response) => {
          this.$vs.loading.close();
          this.createCoursePopup = false;

          this.$vs.notify({
            title: 'Created courses successfully',
            text: `New course ${this.createCourseData.name} has been created.`,
            color: 'success',
            position: 'top-right',
          });

          if (response.status === 200) {
            const data = response.data;
            this.$router.push(`/a/courses/${data.id}`);
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          let error = 'An unknown error occurred while creating this course';
          if (exception.response) {
            error = exception.response.data.message;
          }
          return this.$vs.notify({
            title: 'Failed to create courses',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery() {
      this.currentPage = 0;
    },
    loadProductBuffer(page = 0) {
      if (page === 0) {
        page = this.currentPage;
      }

      this.$http
        .get(`course?pageSize=${this.paginationPageSize}&page=${page}&search=${this.searchQuery}`)
        .then((response) => {
          if (response.data) {
            this.courseData = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalUsers = response.data.total;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading courses';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load courses',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
};
</script>

<style lang="scss"></style>
