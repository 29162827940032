<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
    <feather-icon @click="confirmDeleteRecord" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" />
  </div>
</template>

<script>
export default {
  methods: {
    editRecord() {
      this.$router.push(`courses/${this.params.data.id}`).catch(() => {});
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Archive',
        text: 'Please confirm you want to archive this courses.',
        accept: this.deleteRecord,
        acceptText: 'Archive',
      });
    },
    deleteRecord() {
      this.$vs.loading();

      this.$http
        .delete(`courses/${this.params.data.id}`)
        .then((response) => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.params.loadProductBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'Course Archived',
              text: 'You have successfully archived this courses',
              position: 'top-right',
            });
          }

          this.showError({ response });
        })
        .catch(this.showError);
    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while archive this courses';
      if (exception.response) {
        error = exception.response.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive courses',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
